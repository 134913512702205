
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Calendar from "@/components/molecules/Calendar.vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "StudentsHistory",
  data() {
    return {
      page: 1,
      instructorsData: {},
      dataAllStudents: [],
      selectedNewDate: null,
      dataNotification: [{
        title: 'Notification',
        value: 'notification'
      }],
      instructorsMeta: null,
      errorInstructors: null,
      sortData: "",
      isCalendarVisible: false,
      filters: ''
    };
  },
  components: {
    AdminPanelNavMenu,
    SortArrow,
    AlertBlock,
    Pagination,
    Select,
    Calendar,
    SelectArrow,
  },
  watch: {
    searchData() {
      this.getHistoryStudents();
      this.page = 1;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.getHistoryStudents();
    this.getStudents();
  },
  methods: {
    getHistoryStudents(params, sort) {
      if (sort === undefined) {
        sort = "date";
      }
      params = `?page=${this.page}&sort=${sort}${this.filters}`;
      this.$store.dispatch("students/getHistoryStudents", params).then(
        (res) => {
          this.instructorsData = res.data;
          this.instructorsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getStudents() {
      const params = "";
      this.$store.dispatch("students/getStudents", params).then(
        (res) => {
          this.dataAllStudents = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getHistoryStudents();
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    handleOptionSelectedStudentName(selectedOption) {
      console.log(selectedOption)
      this.filters =
        this.filters + `&filter[student_name]=${selectedOption.id}`;
      this.getHistoryStudents()
    },
    handleOptionSelectedEventType(selectedOption) {
      this.filters = this.filters + `&filter[event_type]=${selectedOption.value}`;
      this.getHistoryStudents()
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        const Month = selectedDate.getMonth() + 1;
        let monthFormat = "";
        if (Month < 10) {
          monthFormat = "0" + (selectedDate.getMonth() + 1);
        } else monthFormat = (selectedDate.getMonth() + 1).toString();
        const Day = selectedDate.getDate();
        let dayFormat = "";
        if (Day < 10) {
          dayFormat = "0" + selectedDate.getDate();
        } else dayFormat = selectedDate.getDate().toString();
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filters = `&filter[date_range]=${this.selectedStartDate},${this.selectedEndDate}`;
        console.log("ss", this.selectedStartDate, this.selectedEndDate);
        this.getHistoryStudents();
      }
    },
  },
});
